import { useState, useEffect, useCallback, useRef } from "react";
import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  useSandpack,
  SandpackTests,
} from "@codesandbox/sandpack-react";
import SandpackFileExplorer from "@rainetian/sandpack-file-explorer";
import Split from "react-split";
import { Layout, Button, Modal, Card, Row, Col } from "antd";
import "antd/dist/antd.css";
import {
  FileOutlined,
  QuestionCircleOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { sandpackTemplates } from "../../utils/templates";
import "./CodeFrameWork.css";
import React from "react";

const { Sider, Content } = Layout;

interface CodeTemplate {
  path: string;
  code: string;
  state: string;
  readOnly: boolean;
}

interface Question {
  description?: string;
  title?: string;
  codeTemplates?: CodeTemplate[];
  language?: string;
}

interface FileState {
  path: string;
  code: string;
}

interface TestResult {
  tests?: Record<string, any>;
  describes?: Record<string, TestResult>;
}

const CodeFrameWork: React.FC = () => {
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [isExplorerOpen, setIsExplorerOpen] = useState<boolean>(false);
  const [isQuestion, setIsQuestion] = useState<boolean>(false);
  const [question, setQuestion] = useState<Question>({});
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [template, setTemplate] = useState<any>("react");
  const [initialCode, setInitialCode] = useState<Record<
    string,
    CodeTemplate
  > | null>(null);
  const [showTestCases, setShowTestCases] = useState<boolean>(false);
  const [previousFileStates, setPreviousFileStates] = useState<FileState[]>([]);
  const [score, setScore] = useState<{
    total: number;
    passed: number;
    failed: number;
  } | null>(null);
  const { sandpack } = useSandpack();
  const { updateFile } = sandpack;
  const filesRef = useRef<Record<string, string>>({});
  const testResultsRef = useRef<TestResult | null>(null);
  const { activeFile, files } = sandpack;

  useEffect(() => {
    if (activeFile.includes(".test.")) {
      handleRunTests();
    }
  }, [activeFile, files]);

  const questionId = "";

  const triggerQuestion = () => {
    if (questionId) {
      const fetchTemplates = async () => {
        try {
          const response = await fetch(
            `http://localhost:5000/questions/${questionId}`
          );
          const data = await response.json();
          const codeTemplates = data.codeTemplates.reduce(
            (acc: Record<string, CodeTemplate>, template: CodeTemplate) => {
              acc[template.path] = {
                path: template.path,
                code: template.code,
                state: template.state || "existing",
                readOnly: template.readOnly || false,
              };
              return acc;
            },
            {}
          );
          setQuestion({ description: data?.description, title: data?.title });
          setTemplate(data.language || "react");
          setInitialCode(codeTemplates);
        } catch (error) {
          console.error("Failed to fetch templates:", error);
        }
      };
      fetchTemplates();
    } else {
      setTemplate("react");
      setInitialCode({});
      setIsTemplateModalVisible(true);
    }
  };
  useEffect(() => {
    triggerQuestion();
  }, [questionId]);

  useEffect(() => {
    if (initialCode && Object.keys(initialCode).length) {
      Object.entries(initialCode).forEach(([filePath, fileContent]) => {
        if (filesRef.current[filePath] !== fileContent.code) {
          updateFile(filePath, fileContent.code);
          filesRef.current[filePath] = fileContent.code;
        }
      });
    }
  }, [initialCode, updateFile]);

  useEffect(() => {
    if (testResultsRef.current) {
      setShowTestCases(!!testResultsRef?.current);
      testResultsRef.current = null;
    }
  }, [testResultsRef]);

  const handleSaveTemplate = async (fileStates: FileState[]) => {
    if (JSON.stringify(fileStates) !== JSON.stringify(previousFileStates)) {
      setPreviousFileStates(fileStates);
    }
  };

  const handleTemplateSelect = (selectedTemplate: any) => {
    setTemplate(selectedTemplate);
    setIsTemplateModalVisible(false);
    setInitialCode({});
  };

  const ActiveFileLogger: React.FC = () => {
    const { sandpack } = useSandpack();
    const { activeFile, files } = sandpack;

    useEffect(() => {
      const isTestFile = activeFile.includes(".test.");
      if (isTestFile) {
        const updatedFile = files[activeFile]?.code;
        if (updatedFile && filesRef.current[activeFile] !== updatedFile) {
          filesRef.current[activeFile] = updatedFile;
          setShowTestCases(false);
          handleRunTests();
        }
      }
    }, [activeFile, files]);

    return null;
  };

  const SandpackLogger: React.FC<{
    onSave: (fileStates: FileState[]) => void;
  }> = ({ onSave }) => {
    const { sandpack } = useSandpack();
    const { files, activeFile } = sandpack;

    const handleSubmit = () => {
      const fileStates = Object.entries(files).map(([filePath, fileData]) => ({
        path: filePath,
        code: fileData.code,
      }));

      onSave(fileStates);
      if (activeFile.includes(".test.")) {
        handleRunTests();
      }
    };

    return (
      <div
        style={{
          padding: "0.5rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button onClick={handleSubmit} type="primary">
          Submit Code
        </Button>
        <Button onClick={handleRunTests} type="primary">
          Show Test Cases
        </Button>
        <Button onClick={handleCloseTests} type="primary">
          Hide Test Cases
        </Button>
      </div>
    );
  };

  const handleRunTests = () => {
    setShowTestCases(true);
  };

  const handleCloseTests = useCallback(() => {
    setShowTestCases(false);
  }, []);

  const handleTestResults = useCallback(
    (results: Record<string, TestResult>) => {
      testResultsRef.current = results;

      let totalTests = 0;
      let passedTests = 0;
      let failedTests = 0;

      const traverseResults = (node: TestResult) => {
        if (!node) return;

        if (node.tests && typeof node.tests === "object") {
          Object.values(node.tests).forEach((test: any) => {
            totalTests++;
            if (test.status === "pass") {
              passedTests++;
            } else if (test.status === "fail") {
              failedTests++;
            }
          });
        }

        if (node.describes && typeof node.describes === "object") {
          Object.values(node.describes).forEach((describeNode) => {
            traverseResults(describeNode);
          });
        }
      };

      Object.values(results).forEach((file) => {
        traverseResults(file);
      });

      const score = {
        total: totalTests,
        passed: passedTests,
        failed: failedTests,
      };

      setScore(score);
    },
    []
  );

  console.log("Scrore --- ", score);

  if (!initialCode) {
    return <div>Loading...</div>;
  }

  return (
    <SandpackProvider template={template} files={initialCode} theme="dark">
      <Layout
        className={`${
          !showTestCases && "content-layout-height"
        } content-layout`}
      >
        <div className="sidebar">
          <Button
            type="primary"
            icon={<CodeOutlined />}
            onClick={() => {
              setIsTemplateModalVisible(true);
              setActiveButton("template");
            }}
            className={`icon-button`}
          />
          {questionId && (
            <Button
              type="primary"
              icon={<QuestionCircleOutlined />}
              onClick={() => {
                setIsQuestion((prev) => !prev);
                setIsExplorerOpen(false);
                setActiveButton("question");
              }}
              className={`icon-button`}
            />
          )}
          <Button
            type="primary"
            icon={<FileOutlined />}
            onClick={() => {
              setIsExplorerOpen((prev) => !prev);
              setIsQuestion(false);
              setActiveButton("file");
            }}
            className={`icon-button`}
          />
        </div>
        <Sider
          collapsible
          collapsed={!isQuestion}
          width={400}
          collapsedWidth={0}
          trigger={null}
          className="question-explorer"
        >
          <div
            className="question-content"
            style={{
              height: showTestCases ? "calc(100vh - 14.5rem)" : "100vh",
            }}
          >
            <h2>{question?.title}</h2>
            <p>{question?.description}</p>
          </div>
        </Sider>
        <Modal
          title="Choose a Template"
          open={isTemplateModalVisible}
          onCancel={() => setIsTemplateModalVisible(false)}
          footer={null}
          width={600}
        >
          <Row gutter={[16, 16]}>
            {sandpackTemplates.map((template: any) => (
              <Col span={8} key={template.templateKey}>
                <Card
                  hoverable
                  onClick={() => handleTemplateSelect(template.templateKey)}
                  style={{ textAlign: "center" }}
                >
                  <div style={{ marginBottom: 16 }}>
                    <div>{template.logo}</div>
                  </div>
                  <Card.Meta title={template.name} />
                </Card>
              </Col>
            ))}
          </Row>
          {questionId && (
            <Button
              type="primary"
              style={{ marginTop: "15px" }}
              onClick={triggerQuestion}
            >
              Back to Question
            </Button>
          )}
        </Modal>
        <Sider
          collapsible
          collapsed={!isExplorerOpen}
          width={300}
          trigger={null}
          className="file-explorer"
        >
          <div className="file-explorer-content">
            <SandpackFileExplorer
              style={{
                height: showTestCases ? "70vh" : "100vh",
                fontSize: "14px",
              }}
            />
          </div>
        </Sider>
        <Content className={`content ${isExplorerOpen ? "" : "shifted"}`}>
          <SandpackLayout
            style={{
              height: showTestCases
                ? "calc(100vh - 18rem)"
                : "calc(100vh - 3rem)",
            }}
          >
            <Split
              className="split"
              sizes={[50, 50]}
              minSize={200}
              gutterSize={3}
            >
              <div className="editor-pane">
                <SandpackCodeEditor
                  style={{ height: "100%" }}
                  showLineNumbers
                  showTabs
                  closableTabs
                  showInlineErrors
                  wrapContent
                />
              </div>
              <div className="preview-pane">
                <SandpackPreview style={{ height: "100%" }} />
              </div>
            </Split>
          </SandpackLayout>
          <ActiveFileLogger />
          <SandpackLogger onSave={handleSaveTemplate} />
        </Content>
      </Layout>
      {showTestCases && (
        <div
          className={`testRunner ${
            showTestCases ? "show-testcase" : "hide-testcase"
          }`}
        >
          <SandpackTests verbose onComplete={handleTestResults} />
        </div>
      )}
    </SandpackProvider>
  );
};

export default CodeFrameWork;
