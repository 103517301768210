import CodeFrameWork from "./CodeFrameWorks/CodeFrameWork";
import { SandpackProvider } from "@codesandbox/sandpack-react";

const WrappedCodeFrameWork = () => (
  <SandpackProvider theme="dark">
    <CodeFrameWork />
  </SandpackProvider>
);

export { WrappedCodeFrameWork as CodeFrameWork };
