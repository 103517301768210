import { SideBarItem } from "@idsk/components-props";
import { Header, SideBar } from "@idsk/components-ui";
import { RootRouter } from "../Router";
import { THEME } from "../theme";
import { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { UserContext } from "@idsk/ui-core-framework";
import { useLocation, useNavigate } from "react-router";
import { getAxiosInstance } from "@idsk/ui-core-framework";
import {
  CarryOutOutlined,
  BarChartOutlined,
  DashboardOutlined,
  FilePdfOutlined,
  ReadOutlined,
  FileTextOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { Layout, message } from "antd";
import { StintCodeLogo } from "../assets/StintCodeLogo.icon";
import { withTheme } from "styled-components";
import "./HomeContainer.scss";

export const HomeContainer = withTheme((props: any) => {
  const axiosInstance = getAxiosInstance();
  const [collapsed, setCollapsed] = useState<boolean>(
    window.screen.width <= 768
  );

  const { mail, id, setUser, isSignedIn } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectUserToAssessment = async () => {
      const redirectPath = localStorage.getItem("redirectPath");

      if (redirectPath && isSignedIn) {
        const urlParts = redirectPath.split("/");
        const redirectPathClientId = urlParts[2];
        const redirectPathId = urlParts[4];

        if (!mail) {
          message.error("No mail found for candidate.");
          return;
        }

        if (redirectPathClientId && redirectPathId) {
          try {
            const payload = {
              candidateEmailIds: [mail],
              invitationType: "CANDIDATES_EMAIL_IDS",
              status: "CREATED",
            };
            const response = await axiosInstance.patch(
              `/v1/d2s/questionandtests/clients/${redirectPathClientId}/assessmentAdministrations/${redirectPathId}`,
              payload
            );

            if (response.data?.statusMessage === "SUCCESS") {
              setTimeout(() => {
                navigate(redirectPath);
              }, 5000);
            } else {
              message.error(`API responded with an error: ${response.data}`);
            }
          } catch (error) {
            message.error(`Error Occurred: ${error}`);
          } finally {
            localStorage.removeItem("redirectPath");
          }
        }
      }
    };
    redirectUserToAssessment();
  }, []);

  useEffect(() => {
    setCollapsed(window.screen.width <= 768);
  }, [window.screen.width]);
  const onContactClick = () => {
    window.open("mailto:contact@interviewdesk.in");
  };

  const onSignOutClick = () => {
    Auth.signOut()
      .then(() => {
        setUser?.(null);
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  const onSideBarItemClick = (
    sectionId: string,
    sideBarItem: SideBarItem
  ): void => {
    if (sideBarItem.id === "dashboard") {
      navigate(`/clients/self/dashboard`);
    } else if (sideBarItem.id === "assessmentSessions") {
      navigate(`/assessment-sessions`);
    } else if (sideBarItem.id === "learn") {
      navigate(`/learn`);
    } else if (sideBarItem.id === "practice") {
      navigate(`/clients/self/practice`);
    } else if (sideBarItem.id === "airesumebuilder") {
      navigate(`/airesumebuilder`);
    } else {
      navigate(sideBarItem.id);
    }
  };

  const items = [];

  items.push({
    id: "dashboard",
    title: "",
    data: [
      {
        icon: DashboardOutlined,
        id: "dashboard",
        title: "Dashboard",
      },
    ],
  });

  items.push({
    id: "assessment",
    title: "Evaluate",
    data: [
      {
        icon: CarryOutOutlined,
        id: "assessmentSessions",
        title: "Assessments",
      },
    ],
  });

  items.push({
    id: "codeIde",
    title: "Code IDE",
    data: [
      {
        icon: CodeOutlined,
        id: "codeIde",
        title: "Code IDE",
      },
    ],
  });

  items.push({
    id: "learn",
    title: "Learn",
    data: [
      {
        icon: ReadOutlined,
        id: "learn",
        title: "Learn",
      },
      {
        icon: BarChartOutlined,
        id: "practice",
        title: "Practice",
      },
    ],
  });

  items.push({
    id: "resumeBuilder",
    title: "Resume",
    data: [
      {
        icon: FilePdfOutlined,
        id: "resumebuilder",
        title: "Resume Builder",
      },
      {
        icon: FileTextOutlined,
        id: "airesumebuilder",
        title: "AI Resume Builder",
      },
    ],
  });

  const featureTestingUserIds = ["9bde055d-4362-40d3-a67c-41abb6bd129c"];
  const hostname = window.location.hostname;
  if (
    hostname.startsWith("arena.interviewdesk.in") ||
    hostname.startsWith("arena.interviewdesk.ai")
  ) {
    if (!featureTestingUserIds.includes(id!)) {
      items[2].data.splice(1, 1);
    }
  }

  const ProfileObject = {
    displayName: mail.split("@")[0],
    avatar: mail.split("@")[0],
  };

  enum NavMenuIconState {
    EXPANDED,
    COLLAPSED,
    DISABLED,
  }

  let selectedSideBarTab = "";
  if (location.pathname.includes("dashboard")) {
    selectedSideBarTab = "dashboard";
  } else if (location.pathname.includes("assessment-sessions")) {
    selectedSideBarTab = "assessmentSessions";
  } else if (location.pathname.includes("learn")) {
    selectedSideBarTab = "learn";
  } else if (location.pathname.includes("practice")) {
    selectedSideBarTab = "practice";
  } else if (location.pathname.includes("resumebuilder")) {
    selectedSideBarTab = "resumebuilder";
  }

  const onNavMenuIconClick = (a: NavMenuIconState) => {
    setCollapsed(a === NavMenuIconState.COLLAPSED);
  };

  const { Content } = Layout;

  const isRootRouterPath =
    location.pathname.startsWith("/lms") ||
    location.pathname.startsWith("/learn") ||
    location.pathname.startsWith("/admin");

  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const hasLearningPath = path.includes("learning");
  const hasNavBarQuery = queryParams.get("navBar") !== null;

  const shouldShowCollapsedSideBar = hasLearningPath
    ? hasNavBarQuery
      ? true
      : false
    : true;

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      {shouldShowCollapsedSideBar && (
        <SideBar
          collapsed={collapsed}
          onItemClick={onSideBarItemClick}
          items={items}
          onContactClick={onContactClick}
          theme={THEME}
          onLogoClick={() => navigate(`/`)}
          logo={StintCodeLogo}
          whiteLabelLogo={props.theme.companyLogo ?? undefined}
          selectedItemId={selectedSideBarTab}
        />
      )}
      <Layout>
        {!hasLearningPath ? (
          <Header
            onSignOutClick={onSignOutClick}
            onNavMenuIconClick={onNavMenuIconClick}
            profile={ProfileObject}
            navMenuIconState={NavMenuIconState.EXPANDED}
            theme={THEME}
          />
        ) : (
          <div style={{ height: "80px" }} />
        )}
        <Content className={isRootRouterPath ? "" : "custom-content"}>
          <RootRouter />
        </Content>
      </Layout>
    </Layout>
  );
});
